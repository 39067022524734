/* eslint-disable max-len */
import Typography from '@mui/material/Typography'
import { CircularProgress } from '@mui/material'
import React from 'react'
import Grid from '@mui/material/Grid'

export default function Loading() {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <CircularProgress size={10} />
      <Typography fontSize={12} sx={{ mx: 1 }} color="text.secondary">
        Loading...
      </Typography>
    </Grid>
  )
}
