import React from 'react'
import Image from 'next/image'
import AppStore from '@/public/images/app-store.png'
import Link from 'next/link'

export const iOSAppLink = 'livelong://apps.apple.com/us/app/livelong-health/id1670617915' // Replace with your custom URL scheme
export const appleStoreLink = 'https://apps.apple.com/us/app/livelong-health/id1670617915'

export const getReportIOSDeepLink = (deepLink?: string | undefined) => {
  if (deepLink) {
    return iOSAppLink + `/results?l=id&s=${deepLink}`
  }
  return iOSAppLink
}

function AppleStoreButton() {
  return (
    <Link target={'_blank'} href={appleStoreLink}>
      <Image src={AppStore} alt="" width="119" style={{ filter: 'grayscale(100%)', cursor: 'pointer' }} />
    </Link>
  )
}

export default AppleStoreButton
