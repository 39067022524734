import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import Head from 'next/head'

export interface GuestLayoutProps {
  children: any
  title: string
}

const GuestLayout = ({ children, title = 'livelong panel' }: GuestLayoutProps) => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100wh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Head>
        <title>{title}</title>
      </Head>

      <Grid>{children}</Grid>
    </Box>
  )
}

export default GuestLayout
