import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import GooglePlay from '@/public/images/google-play.png'

export const androidAppLink = 'livelong://play.google.com/store/apps/details' // Replace with your custom URL scheme
export const googlePlayLink = 'https://play.google.com/store/apps/details?id=ai.HeartLung'

export const getReportAndroidDeepLink = (deepLink?: string) => {
  if (deepLink) {
    return androidAppLink + `/results?s=${deepLink}&id=ai.HeartLung`
  }
  return androidAppLink + '?id=ai.HeartLung'
}

function GooglePlayButton() {
  return (
    <Link target={'_blank'} href={googlePlayLink}>
      <Image src={GooglePlay} alt="" width="119" style={{ filter: 'grayscale(100%)', cursor: 'pointer' }} />
    </Link>
  )
}

export default GooglePlayButton
