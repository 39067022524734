/* eslint-disable max-len */
import { IInputError } from '@/components/layout/auth-layout'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export interface IAuthInput {
  id: string
  name: string
  value: string
  required: boolean
  readOnly?: boolean
  type: string
  title: string
  error?: IInputError | null
  placeholder: string
  helperText: string
  onChange: any
  showInputAdornment?: boolean
  onHandleClickInputAdornment?: any
  onHandleMouseInputAdornment?: any
  onKeyPress?: any
  inputProps?: any
}

function EndAdornment({ name, onHandleClickInputAdornment, onHandleMouseInputAdornment, showInputAdornment }: any) {
  return (
    <InputAdornment position="end">
      {onHandleClickInputAdornment && (
        <>
          <IconButton
            aria-label={`"toggle ${name} visibility"`}
            onClick={onHandleClickInputAdornment}
            onMouseDown={onHandleMouseInputAdornment}
            edge="end"
          >
            {showInputAdornment ? <VisibilityOff/> : <Visibility/>}
          </IconButton>
        </>
      )}
    </InputAdornment>
  )
}

export default function AuthInput({
  required,
  readOnly=false,
  type,
  title,
  id,
  name,
  value,
  error,
  placeholder,
  onChange,
  helperText,
  showInputAdornment = false,
  onHandleClickInputAdornment = null,
  onHandleMouseInputAdornment = null,
  onKeyPress = null,
  inputProps = {},
}: IAuthInput) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={0}
      sx={{ width: '100%' }}
    >
      <Typography
        component="small"
        sx={{
          width: '100%',
          fontSize: 14,
          fontWeight: 400,
          color: 'neutral.500',
        }}
      >
        {title}
      </Typography>
      <OutlinedInput
        readOnly={readOnly}
        required={required}
        fullWidth
        id={id}
        name={name}
        sx={{ width: '100%', mb: 1.5, mt: 0.5, background: 'white', borderColor: 'red', borderRadius: 1.5, color: 'natural.900' }}
        type={type}
        value={value}
        error={!!error}
        onChange={onChange}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        inputProps={inputProps}
        endAdornment={
          onHandleClickInputAdornment && (
            <EndAdornment
              name={name}
              onHandleClickInputAdornment={onHandleClickInputAdornment}
              onHandleMouseInputAdornment={onHandleMouseInputAdornment}
              showInputAdornment={showInputAdornment}
            />
          )
        }
      />
      <Typography
        component="small"
        sx={{
          width: '100%',
          fontSize: 12,
          fontWeight: 300,
          color: `${error ? 'error.main' : 'neutral.500'}`,
        }}
      >
        {error ? error?.message : helperText}
      </Typography>
    </Grid>
  )
}
